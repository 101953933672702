<div class="d-inline-block">
  <span class="rv-icon-feather-globe"></span>
</div>

<div class="d-inline-block">
  <select
    (change)="onChangeLanguage()"
    [(ngModel)]="selectedLanguage"
    class="form-select border-0 language-selector"
    aria-label="Default select example">
    <option
      *ngFor="let item of languages"
      [value]="item.languageCode?.toUpperCase()"
      [selected]="item.languageCode?.toUpperCase() === selectedLanguage.toUpperCase()"
      (click)="onChangeLanguage()">
      {{ item.languageName }}
    </option>
  </select>
</div>
