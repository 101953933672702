import { Component } from '@angular/core';
import { ILanguageModel } from '@d-models/languages.model';
import { ILocalizationModel } from '@d-models/selected-localization.model';
import { DEFAULT_LANGUAGE_CODE } from '@d-well-know-types/app.constants';
import { LocalStorageService } from 'app/shared/services/local-storage.service';

@Component({
  selector: 'app-languages-selector',
  templateUrl: './languages-selector.component.html',
  styleUrls: ['./languages-selector.component.scss'],
})
export class LanguagesSelectorComponent {
  localization!: ILocalizationModel | null;
  oldSelectedLanguage!: string;
  selectedLanguage!: string;
  languages: ILanguageModel[] = [];

  constructor(private localStorageService: LocalStorageService) {
    this.localStorageService.$localization.subscribe(lo => {
      if (lo) {
        this.localization = lo;
      } else {
        this.localization = this.localStorageService.getLocalization();
      }

      this.languages = this.localization?.supportLanguages ?? [];
      this.selectedLanguage = (this.localization?.currentLanguageCode ?? DEFAULT_LANGUAGE_CODE).toUpperCase();
      this.oldSelectedLanguage = this.selectedLanguage;
    });
  }

  onChangeLanguage() {
    (this.localization as ILocalizationModel).currentLanguageCode = this.selectedLanguage;
    this.localStorageService.setLocalization(this.localization as ILocalizationModel);

    let urlPaths = location.pathname.split('/');
    let url = urlPaths.slice(0, urlPaths.length - 2).join('/');

    window.location.href = `${url}/${this.selectedLanguage}/${this.localization?.currentCountryCode}`;
  }
}
