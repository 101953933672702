<div class="menu text-center footer-menu">
  <a
    class=""
    [routerLink]="'/legal/privacypolicy/' + localization?.currentLanguageCode + '/' + localization?.currentCountryCode"
    [appTranslator]="'settings_privacy_policy'"></a>
  <a
    class=""
    [routerLink]="'/legal/termsofservice/' + localization?.currentLanguageCode + '/' + localization?.currentCountryCode"
    [appTranslator]="'settings_terms_service'"></a>
  <a
    class=""
    [routerLink]="'/legal/termsofuse/' + localization?.currentLanguageCode + '/' + localization?.currentCountryCode"
    [appTranslator]="'settings_terms_use'"></a>
  <a
    class=""
    [routerLink]="'/legal/programterms/' + localization?.currentLanguageCode + '/' + localization?.currentCountryCode"
    title="Program Terms"
    [appTranslator]="'settings_promo_terms'"></a>
  <a class="" 
    [routerLink]="'/legal/faq/' + localization?.currentLanguageCode + '/' + localization?.currentCountryCode" 
    [appTranslator]="'settings_faq_text'"></a>
  <div class="d-inline-flex" *ngIf="isShowOneTrustBadge">
    <a
      target="_blank"
      href="https://privacyportal-cdn.onetrust.com/dsarwebform/d19e506f-1a64-463d-94e4-914dd635817d/b9eb997c-9ede-451b-8fd4-29891782a928.html"
      [appTranslator]="'legal_suboption_do_not_sell_personal_information'">
    </a>
    <img height="18" width="38" class="one-trust-logo ms-2" src="assets/logos/one-trust-logo.png" alt="One Trust" />
  </div>
  <div class="lang-selector">
    <app-languages-selector></app-languages-selector>
  </div>
</div>
