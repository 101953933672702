<div *ngIf="(availableCountries$ | async)">
  <div class="container text-center pt-4 pb-4 text-white">
    <h2 class="text-start py-1 landing-title"
        [appTranslator]="'ridevuWording.home-select-country | default | Select your Country or Region'"></h2>
    <hr/>
    <div class="row align-items-start text-start" *ngIf="countryGroups.length > 0">
      <ng-container *ngFor="let item of countryGroups; index as i">
        <div class="col-6 col-md-3 col-lg-3">
          <div class="row">
            <h3 class="landing-continent" [appTranslator]="item.continent"></h3>
            <div class="row rv-region">
              <div *ngFor="let country of item.countries" class="row">
                <a href="javascript:void(0)" (click)="onCountrySelect(country)"
                >{{ country.countryName }}
                  <i
                    *ngIf="localization && localization.currentCountryCode === country.countryCode"
                    class="ms-2 light-blue-icon rv-icon-circle-check"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
